import React from "react"

const Footer = () => (
    <footer
      style={{
        background: `#000`,
        height: '175px',
        borderTop: `5px solid #00FFC6`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
      </div>
      
      <div
        style={{
          color: `#00FFC6`,
          textAlign: `center`,
          fontFamily: `Peepo`,
          fontSize: `30px`,
        }}
      >
      brain-freeze.de
      </div>

    </footer>
)

  export default Footer