import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "../components/logo"

const Header = ({ siteTitle }) => (
  <header
    style={{
      /*display: `flex`,*/
      background: `#000`,
      /*marginBottom: `1.45rem`,*/
      height: '175px',
      borderBottom: `5px solid #00FFC6`,
      position: `fixed`,
      width: `100%`,
      zIndex: `1000`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link
        to="/"
        style={{
        }}
      >
        <Logo />
      </Link>


      <h1 style={{ margin: 0, textAlign: `center` }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          {/*{siteTitle}*/}
        </Link>
      </h1>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
